import request from '@/plugins/http'

export function apiJobSet(param) {
  return request.post('/api/job/jobSet', param);
}

export function apiGetJobList() {
  return request.get('/api/job/getJobList');
}

export function apiGetMaxLastDays() {
  return request.get('/api/job/getMaxLastDays');
}

export function apiFinishCurrentDayJob(param) {
  return request.post('/api/job/finishCurrentDayJob', param);
}

export function apiJobDelete(param) {
  return request.post('/api/job/jobDelete', param);
}





