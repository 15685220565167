<template>
  <div id="job-set">
    <div class="display-flex jc-space-between align-items-center">
      <div>
        <h1 class="fw-300">Job Set</h1>
        <h5 class="fw-300">Try something new for 30 days</h5>
      </div>   
      <router-link to="/"><i class="el-icon-back fs-26 m-f-color"></i></router-link>
    </div>
    
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="set-module">
          <el-form ref="setFormInline" :model="jobForm" label-width="80px" :rules="ruleInline">
            <el-form-item label="Title" prop="title">
              <el-input v-model="jobForm.title"></el-input>
            </el-form-item>
            <el-form-item label="Way" prop="way">
              <el-radio-group v-model="jobForm.way" >
                <el-radio label="add" class="fw-300">Add</el-radio>
                <el-radio label="subtract" class="fw-300">Subtract</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="Type" prop="type">
              <el-radio-group v-model="jobForm.type" >
                <el-radio label="day" class="fw-300">Day</el-radio>
                <el-radio label="week" class="fw-300">Week</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="jobSetSubmit('setFormInline')" :loading="set_loading">Submit</el-button>
              <el-button @click="resetJobForm('setFormInline')">Reset</el-button>
              <el-button v-show="jobForm.id>0" type="warning" @click="deleteJobInfo('setFormInline')">Delete</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-col>

      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="p-20">
          <div class="align-center"><span class="fs-18">Add</span></div>
          <div class="mt-10 display-flex align-items-center jc-center cursor-pointer" @click="editJobInfo(item)"   v-for="item,index in list.add" v-bind:key="index">
            <div class="list-item">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="add-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2" >
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成{{item.success_days}}天，还剩{{item.left_days}}天，目标达成</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
        <div class="p-20">
          <div class="align-center"><span class="fs-18">Subtract</span></div>
          <div class="mt-10 display-flex align-items-center jc-center cursor-pointer" @click="editJobInfo(item)"  v-for="item,index in list.subtract" v-bind:key="index">
            <div class="list-item">
              <div class="item-check z-index-2" v-if="item.current_end_flag">
                <i class="el-icon-check"></i>
              </div>
              <div class="subtract-line" :style="'width:'+item.p+'%;'"></div>
              <div class="w-color z-index-2">
                <div class="fs-16">{{ item.title }}</div>
                <div class="fs-12 mt-6" v-if="item.p<100">已连续完成{{item.success_days}}天，还剩{{item.left_days}}天，目标达成</div>
                <div class="fs-12 mt-6" v-else>目标达成,点个赞！</div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    
  </div>
  </template>
  
  <script>
  import {apiJobSet, apiGetJobList, apiJobDelete} from '@/api/job/job'

  export default {
    name: "JobSet",
    data() {
      return {
        jobForm: {
          title: '',
          way: '',
          type: 'day',
          id: 0,
        },
        ruleInline: {
          title: [
            {required: true, message: 'Enter your title', trigger: 'blur'},
          ],
          way: [
            {required: true, message: 'Select your way',trigger: 'change' },
          ],
          type: [
            {required: true, message: 'Select your type',trigger: 'change' },
          ]
        },
        set_loading: false,
        list: {
          add: [],
          subtract: []
        }
      }
    },
    methods: {
      jobSetSubmit(name) {
        this.set_loading = true;
        let param = {
          title: this.jobForm.title,
          way: this.jobForm.way,
          type: this.jobForm.type,
          id: this.jobForm.id,
        };

        this.$refs[name].validate((valid) => {
          if (valid) {
            apiJobSet(param).then(res => {
              if (res.data.err_no == 0) {
                this.$refs[name].resetFields();
                this.$message({
                  message: res.data.results,
                  type: 'success'
                });   
                
                this.getJobList();
              } else {
                this.$message({
                  message: res.data.err_msg,
                  type: 'error'
                });
              }
              
              this.set_loading = false;
            }).catch(error => {
              this.$message({
                message: "It's a error message",
                type: 'error'
              });
              this.set_loading = false;
            });
            this.set_loading = false;
          } else {
            this.set_loading = false;
          }
        });
      },
      resetJobForm(name) {
        this.jobForm.id = 0;
        this.$refs[name].resetFields();
      },
      deleteJobInfo(name) {
        apiJobDelete({id:this.jobForm.id}).then(res => {
              if (res.data.err_no == 0) {
                this.resetJobForm(name);
                this.$message({
                  message: res.data.results,
                  type: 'success'
                });   
                this.getJobList();
              } else {
                this.$message({
                  message: res.data.err_msg,
                  type: 'error'
                });
              }
            }).catch(error => {
              this.$message({
                message: "It's a error message",
                type: 'error'
              });
        });
      },
      getJobList() {
        apiGetJobList().then(res => {
          if (res.data.err_no == 0) {
            this.list.add = res.data.results.add;
            this.list.subtract = res.data.results.subtract
          }
        })
      },
      editJobInfo(item) {
        this.jobForm.id = item.id;
        this.jobForm.title = item.title;
        this.jobForm.way = item.way;
        this.jobForm.type = item.type;
      }
    },
    mounted() {
      this.getJobList();
    }
  }
  </script>
  
  <style scoped>
  #job-set {
    padding:20px;
    font-weight: 300;
  }

.list-item {
  background-color:#c5c8ce;
  width:560px;
  min-height:60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}

.set-module {
  padding:20px;
  border-radius:4px;
  background-color: #f8f8f9;
}


  </style>
  